<template>
    <v-container>
        <v-dialog v-model="show" max-width="500" persistent>
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('selezionare-giorno') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6">
                    <v-date-picker
                        v-model="day"
                        first-day-of-week="1"
                        @change="getPlan"
                        :min="new Date().toISOString()"
                        :picker-date.sync="pickerDate"
                        :events="events"
                        event-color="green lighten-1"
                    ></v-date-picker>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancel()">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="confirm()">{{ $t('conferma') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'addToPlan',
    components: {},
    data() {
        return {
            id: null,
            visible: null,
            day: null,
            already: false,
            lastOrder: 0,
            pickerDate: null,
            events: []
        }
    },

    props: {
        value: { type: Array },
        edit: { type: Boolean, default: false }
    },
    computed: {
        show: {
            get() {
                return this.value.length ? true : false
            },
            set(value) {
                this.day = null
                this.$emit('input', value)
            }
        }
    },
    watch: {
        value() {
            this.getEvents(this.pickerDate)
        },
        pickerDate(newval, oldval) {
            this.getEvents(newval)
        }
    },
    async created() {},

    methods: {
        async getEvents(val) {
            if (!val) return
            let month = val.substring(0, 7)
            const min = this.$moment(month).format('YYYY-MM-DD')
            const max = this.$moment(month).add(1, 'months').subtract(1, 'days').format('YYYY-MM-DD')
            let doc = []
            let c = {}
            let pa = 1
            do {
                c = await this.$http.get(`plans`, {
                    'filters': {
                        agent: { id: { $eq: this.$auth.utente.agent.id } },
                        day: {
                            $between: [min, max]
                        }
                    },
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa
                })

                doc = doc.concat(c.data)
                pa++
            } while (c.meta.pagination.pageCount > c.meta.pagination.page)

            if (!doc.length) {
                this.events = []
                return
            }
            const days = new Set(doc.map((x) => x.attributes.day))
            this.events = [...days]
        },
        cancel() {
            this.show = []
        },
        async getPlan() {
            this.already = false
            this.lastOrder = 0
            const docs = await this.$http.get(`plans`, {
                filters: {
                    agent: { id: { $eq: this.$auth.utente.agent.id } },
                    day: this.day
                },
                populate: ['agent', 'lead'],
                sort: ['order']
            })
            if (docs.data.length) {
                const esiste = docs.data.find((x) => this.value.includes(x.attributes.lead.data.id))
                if (esiste) {
                    this.already = true
                    return
                }
                const lastorder = docs.data[docs.data.length - 1].attributes.order

                this.lastOrder = lastorder + 1
            }
        },
        async confirm() {
            if (!this.day) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('errore-seleziona-una-data'),
                    type: 'error'
                })
                return
            }
            if (this.already) {
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('lead-gia-presente-nella-data-selezionata'),
                    type: 'error'
                })
                return
            }
            try {
                if (this.edit) {
                    for (const x of this.value) {
                        let data = {
                            day: this.day,
                            order: this.lastOrder
                        }
                        this.lastOrder = this.lastOrder + 1

                        const lead = await this.$http.put(`plans/${x}`, {
                            data: data
                        })
                    }
                } else {
                    this.value.forEach(async (x) => {
                        let data = {
                            day: this.day,
                            lead: x,
                            order: this.lastOrder,
                            agent: this.$auth.utente.agent.id
                        }
                        this.lastOrder = this.lastOrder + 1

                        const lead = await this.$http.post(`plans`, {
                            data: data
                        })
                    })
                }

                //success
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('salvataggio-avvenuto-con-successo'),
                    type: 'success'
                })
                this.show = []
            } catch (error) {
                console.log(error)
                this.$notify({
                    group: 'notifica',
                    title: this.$t('salvataggio'),
                    text: this.$t('errore-nel-salvataggio'),
                    type: 'error'
                })
            } finally {
                //this.$router.push(`/`)
            }
        }
    }
}
</script>
<style></style>
