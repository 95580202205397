<template>
    <v-container fluid>
        <!-------------- title ----------------->
        <v-row class="text-center">
            <v-col cols="12" class="mb-n6">
                <h1 :class="{ 'mt-15': !$vuetify.breakpoint.mdAndUp, 'mt-10 pt-10': $vuetify.breakpoint.mdAndUp }">
                    {{ $t('sezione', { page: this.$tc('zona', 2) }) }}
                </h1>
            </v-col>
            <v-col cols="12">
                <v-card class="d-flex justify-center mb-6" flat>
                    <!-------------- new agente
                ----------------->
                    <v-row style="justify-content: center">
                        <template v-if="$permission($auth.utente.role.name, 'editZone')">
                            <v-col cols="2" sm="3" class="d-flex justify-center">
                                <router-link to="/zone/new" class="text-decoration-none">
                                    <v-btn rounded large color="success " class="d-none d-md-flex">
                                        <v-icon class="pa-2">add_circle</v-icon> {{ $t('crea-', { page: this.$tc('zona', 1) }) }}
                                    </v-btn>
                                    <v-icon color="success" class="d-flex d-md-none" x-large>add_circle</v-icon>
                                </router-link>
                            </v-col>
                        </template>

                        <!-------------- search 
                ----------------->
                        <v-col cols="8" sm="6" class="d-flex justify-center mb-4">
                            <v-text-field
                                v-model="search"
                                append-icon="search"
                                :label="$t('ricerca-nome')"
                                single-line
                                hide-details
                                class=""
                                clearable
                            ></v-text-field>
                        </v-col>

                        <!-------------- filter                    ----------------->

                        <template v-if="$permission($auth.utente.role.name, 'editZone')">
                            <v-col cols="2" sm="3" class="d-flex justify-center">
                                <div @click="modalFilter = !modalFilter">
                                    <v-btn large rounded color="success" class="d-none d-md-flex">
                                        <v-icon class="pa-2">filter_alt</v-icon> {{ $t('imposta-filtri') }}
                                    </v-btn>
                                    <v-icon color="success" class="d-flex d-md-none" x-large>filter_alt</v-icon>
                                </div>

                                <div class="text-center">
                                    <v-bottom-sheet v-model="modalFilter">
                                        <v-sheet class="text-center" height="250px">
                                            <!-------------- reset filter ----------------->
                                            <v-container cols="12">
                                                <v-row class="d-flex justify-center">
                                                    <!-- Close button -->
                                                    <v-col cols="3" class="d-flex justify-center" @click="modalFilter = !modalFilter">
                                                        <v-btn class="mt-n8 d-none d-sm-flex" color="error">{{ $t('chiudi') }}</v-btn>
                                                        <v-btn color="error" class="d-flex d-sm-none icon"><v-icon> cancel </v-icon></v-btn>
                                                    </v-col>

                                                    <!-- Reset filters button -->
                                                    <v-col cols="6">
                                                        <v-btn class="mt-n8" color="warning" @click="clearFilters">{{ $t('reset-filtri') }}</v-btn>

                                                        <h1 class="mt-4">{{ $tc('filtro', 2) }}</h1>
                                                    </v-col>

                                                    <!-- Apply button -->
                                                    <v-col cols="3" class="d-flex justify-center" @click="setFilter()">
                                                        <v-btn class="mt-n8 d-none d-sm-flex" color="success">{{ $t('applica') }}</v-btn>
                                                        <v-btn color="success" class="d-flex d-sm-none icon"> <v-icon> search </v-icon></v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>

                                            <div class="pa-6">
                                                <v-col cols="12"> </v-col>
                                                <v-row>
                                                    <v-col cols="12 " sm="12">
                                                        <v-autocomplete
                                                            :label="$t('assegnato-ad-agente')"
                                                            item-text="name"
                                                            :search-input.sync="searchUser"
                                                            cache-items
                                                            multiple
                                                            hide-no-data
                                                            v-model="selectUser"
                                                            :loading="loadingUser"
                                                            :items="User"
                                                            return-object
                                                            solo-inverted
                                                            flat
                                                            chips
                                                            deletable-chips
                                                            clearable
                                                        ></v-autocomplete>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-sheet>
                                    </v-bottom-sheet>
                                </div>
                            </v-col>
                        </template>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <!------------- GRIGLIE ----------------->
        <v-card class="marginScroll">
            <v-data-table
                :headers="headers"
                :items="zones"
                :options.sync="options"
                :server-items-length="totalZones"
                :loading="loading"
                :search="search"
                class="pt-6 elevation-6"
                :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100] }"
                v-model="selectedZones"
                @update:options="updateUrl"
                :mobile-breakpoint="1270"
            >
                <!-- pulsante azioni -->

                <template v-slot:top v-if="$permission($auth.utente.role.name, 'deleteZone')">
                    <v-menu transition="slide-y-transition" bottom v-if="selectedZones.length" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" class="ml-6 mt-n2 mb-4" v-bind="attrs" v-on="on"> {{ $tc('azione', 2) }} </v-btn>
                        </template>
                        <v-list class="text-uppercase">
                            <v-list-item @click="showDeleteDialogSelect">
                                <v-list-item-title class="ma-4"
                                    ><v-icon color="black" class="mb-1" left> delete </v-icon> {{ $t('elimina') }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>
                <!------------- azione ----------------->

                <template #[`item.id`]="{ value }">
                    <v-container class="d-flex flex-row mr-n2" :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4'" flat tile>
                        <router-link :to="`/zone/${value}/edit`">
                            <v-icon>edit</v-icon>
                        </router-link>
                        <router-link :to="`/zone/${value}`">
                            <v-icon class="pr-3 pl-3">visibility</v-icon>
                        </router-link>
                        <v-icon @click="showDeleteDialog(value)" v-if="$permission($auth.utente.role.name, 'deleteZone')">cancel</v-icon>
                    </v-container>
                </template>

                <!-- Aggiunta colonna "Assegnato ad agente" -->
                <template #[`item.agents`]="{ value }">
                    <v-list-item-content>
                        <v-list-item-title>
                            <template v-if="value.length > 0">
                                <template v-if="value.length <= 2">
                                    {{ value.join(', ') }}
                                </template>
                                <template v-else>
                                    <v-btn color="primary" @click="showAllAgents(value)"> {{ $t('guarda-tutto') }} </v-btn>
                                </template>
                            </template>
                            <template v-else> - </template>
                        </v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-data-table>
            <v-dialog v-model="showAllAgentsDialog" max-width="500">
                <v-card>
                    <v-card-title class="headline justify-center">{{ $t('agenti-assegnati') }}</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="agent in allAgents" :key="agent.id">
                                <v-list-item-content>
                                    <v-list-item-title>{{ agent }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn color="primary" @click="showAllAgentsDialog = false">{{ $t('chiudi') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
        <v-dialog v-model="showDeleteConfirmation" max-width="500">
            <v-card outlined>
                <v-card-title class="text-h5 grey justify-center lighten-2"> {{ $t('confermi-') }} </v-card-title>

                <v-card-text class="mt-6, text-center mt-6"> {{ $t('sei-sicuro-di-voler-eliminare-la-zona') }} </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn text @click="cancelDelete">{{ $t('annulla') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="deleteZ(deleteDialogCall)">{{ $t('elimina') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    name: 'Zones',
    data() {
        return {
            zones: [],
            totalZones: 0,
            loading: true,
            options: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                groupBy: [],
                groupDesc: [],
                multiSort: null,
                mustSort: null
            },
            search: '',
            timeoutQuery: null,
            showAllAgentsDialog: false,
            showDeleteConfirmation: false,
            deleteDialogMsg: '',
            deleteDialogCall: null,
            allAgents: [],
            headers: [
                { text: this.$tc('nome-zona', 1), value: 'name', field: 'Name' },
                {
                    text: this.$tc('numero-leads', 1),
                    value: 'leads',
                    field: 'leads',
                    sortable: false
                }
            ],
            zoneToDelete: null,
            modalFilter: false,
            searchUser: null,
            selectUser: [],
            loadingUser: false,
            User: [],
            selectedZones: [],
            changinurl: false
        }
    },
    watch: {
        search: {
            handler() {
                if (!this.search || this.search.length == 0) {
                    this.setFilter()
                    return
                }
                if (this.search.length < 3) {
                    return
                }
                if (this.timeoutQuery) {
                    clearTimeout(this.timeoutQuery)
                }
                this.timeoutQuery = setTimeout(() => {
                    this.setFilter()
                }, 300)
            }
        },
        searchUser(val) {
            val && this.getUser(val)
        }
    },
    async created() {
        if (!this.$permission(this.$auth.utente.role.name, 'ZonePage')) {
            this.$router.push('/')
        }
        this.changinurl = true
        if (this.$route.query.page) {
            this.options.page = parseInt(this.$route.query.page)
        }
        if (this.$route.query.items) {
            this.options.itemsPerPage = parseInt(this.$route.query.items)
        }
        if (this.$route.query.sortBy) {
            this.options.sortBy[0] = this.$route.query.sortBy
            if (this.$route.query.sortDesc) {
                this.options.sortDesc[0] = this.$route.query.sortDesc
            }
        }
        if (this.$route.query.search) {
            this.search = decodeURIComponent(this.$route.query.search)
        }

        if (this.$route.query.agente) {
            await this.getUserFromApiId(this.$route.query.agente)
        }

        //this.getDataFromApi()
        if (this.$permission(this.$auth.utente.role.name, 'editZone')) {
            this.headers = [
                {
                    text: this.$tc('azione', 1),
                    align: 'start',
                    sortable: false,
                    value: 'id',
                    field: 'id'
                },
                ...this.headers,
                {
                    text: this.$tc('assegnato-ad-agente', 1),
                    value: 'agents',
                    field: 'agents.Name'
                }
            ]
            if (this.$route.query.agente) {
                await this.getAgentsFromApiId(this.$route.query.agente)
            }
        }
        this.changinurl = false
    },
    methods: {
        clearFilters() {
            // Reset all filter
            this.selectUser = []
        },
        updateUrl() {
            if (this.changinurl) return

            let query = { ...this.$route.query }
            this.changinurl = true
            if (this.options.page > 1) {
                if (this.$route.query.page != this.options.page) {
                    query.page = this.options.page
                }
            } else {
                delete query.page
            }
            if (this.options.itemsPerPage > 10) {
                if (this.$route.query.items != this.options.itemsPerPage) {
                    query.items = this.options.itemsPerPage
                }
            } else {
                delete query.items
            }
            if (this.options.sortBy.length) {
                if (this.$route.query.sortBy != this.options.sortBy) {
                    query.sortBy = this.options.sortBy
                    if (this.options.sortDesc.length && this.$route.query.sortDesc != this.options.sortDesc) {
                        query.sortDesc = this.options.sortDesc
                    }
                }
            } else {
                delete query.sortBy
                delete query.sortDesc
            }
            if (this.search) {
                if (this.$route.query.search != this.search) {
                    query.search = encodeURIComponent(this.search)
                }
            } else {
                delete query.search
            }

            if (this.selectUser.length) {
                if (this.$route.query.agente != this.selectUser.map((x) => x.id).join(',')) {
                    query.agente = this.selectUser.map((x) => x.id).join(',')
                }
            } else {
                delete query.agente
            }

            this.$router
                .replace({
                    path: this.$route.path,
                    query: query
                })
                .catch((err) => {})
            this.getDataFromApi()
            this.changinurl = false
        },
        getUser(val) {
            if (val.length < 2) {
                return
            }
            if (this.timeoutQuery) {
                clearTimeout(this.timeoutQuery)
            }
            this.timeoutQuery = setTimeout(() => this.getUserFromApi(val), 300)
        },
        async getUserFromApi(val) {
            this.loadingUser = true
            const p = await this.$http.get(`agents`, { 'pagination[pageSize]': 200, 'filters': { Name: { $containsi: val } } })
            if (!p.data.length) {
                this.loadingUser = false
                return
            }
            this.User = p.data.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.loadingUser = false
        },
        async getUserFromApiId(val) {
            this.loadingUser = true
            let filters = {}
            let c = 0
            val.split(',').forEach((x) => {
                filters[`filters[$or][${c}][id][$eq]`] = x
                c++
            })
            let ci = []
            let cc = {}
            let pa = 1
            do {
                cc = await this.$http.get(`agents`, {
                    'pagination[pageSize]': 200,
                    'pagination[page]': pa,
                    ...filters
                })
                ci = ci.concat(cc.data)
                pa++
            } while (cc.meta.pagination.pageCount > cc.meta.pagination.page)
            if (!cc.data.length) {
                this.loadingUser = false
                return
            }
            this.User = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })
            this.selectUser = ci.map((x) => {
                return { id: x.id, name: x.attributes.Name }
            })

            this.loadingUser = false
        },
        setFilter() {
            this.options.page = 1
            this.updateUrl()
            this.modalFilter = false
        },
        showDeleteDialog(zoneId) {
            this.zoneToDelete = zoneId
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-la-zona')
            this.deleteDialogCall = true
            this.showDeleteConfirmation = true
        },
        showDeleteDialogSelect() {
            this.deleteDialogMsg = this.$t('sei-sicuro-di-voler-eliminare-le-zone-selezionate')
            this.deleteDialogCall = false
            this.showDeleteConfirmation = true
        },
        deleteZ(type = false) {
            if (type) {
                this.deleteZone()
            } else {
                this.deleteSelect()
            }
        },
        async deleteZone() {
            try {
                // Effettua la chiamata HTTP per eliminare il lead
                await this.$http.delete(`zones/${this.zoneToDelete}`)

                // Eliminazione riuscita, esegui le azioni necessarie
                // ...

                // Aggiorna i dati dopo l'eliminazione
                await this.getDataFromApi()

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.zoneToDelete = null
            } catch (error) {
                // Gestisci l'errore durante l'eliminazione del lead
                console.error(error)

                // Mostra una notifica di errore
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-della-zona'),
                    type: 'error'
                })

                // Chiudi il dialogo di conferma ed esegui altre azioni
                this.showDeleteConfirmation = false
                this.zoneToDelete = null
            }
        },

        cancelDelete() {
            this.showDeleteConfirmation = false
            this.leadToDelete = null
            this.deleteDialogMsg = ''
            this.deleteDialogCall = false
        },
        async getDataFromApi() {
            this.loading = true
            const { sortBy, sortDesc, page, itemsPerPage } = this.options
            let filters = {}

            let cc = 0
            if (this.search) {
                filters[`filters[$and][${cc}][Name][$containsi]`] = this.search
                cc++
            }
            if (this.selectUser) {
                let c = 0
                this.selectUser.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][agents][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            if (this.$permission(this.$auth.utente.role.name, 'groupLeadTable')) {
                if (!this.$auth.utente.agent.zones.length) {
                    this.loading = false
                    return
                }
                let c = 0
                this.$auth.utente.agent.zones.forEach((x) => {
                    filters[`filters[$and][${cc}][$or][${c}][id][$eq]`] = x.id
                    c++
                })
                cc++
            }
            let sort = 'Name'
            if (sortBy.length) {
                const el = this.headers.find((x) => x.value == sortBy[0])
                sort = el.field.concat(sortDesc[0] ? ':desc' : '')
            }
            const docs = await this.$http.get('zonelist', {
                pageSize: itemsPerPage,
                page: page,
                populate: ['leads', 'leads.parent_lead', 'agents'],
                sort: sort,
                ...filters
            })

            let tmp = docs.results || docs

            this.zones = tmp
            this.totalZones = docs.pagination.total ? docs.pagination.total : 0
            this.loading = false
        },
        showAllAgents(agents) {
            this.allAgents = agents
            this.showAllAgentsDialog = true
        },
        async deleteSelect() {
            if (!this.$permission(this.$auth.utente.role.name, 'deleteZone')) {
                return
            }
            try {
                for (const zona of this.selectedZones) {
                    await this.$http.delete(`zones/${zona.id}`)
                }

                this.getDataFromApi()
                this.selectedZones = []
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('successo'),
                    text: this.$t('zone-selezionate-cancellate-con-successo'),
                    type: 'success'
                })
            } catch (error) {
                console.error(error)
                this.cancelDelete()
                this.$notify({
                    group: 'notifica',
                    title: this.$t('errore'),
                    text: this.$t('si-e-verificato-un-errore-durante-leliminazione-delle-zone-selezionate'),
                    type: 'error'
                })
            }
        }
    }
}
</script>
